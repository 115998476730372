import CustomLayout from '@/common/Layout';
import React from 'react';
import CloningPage from './components/Cloning';

function ActionsRequired() {
  return (
    <CustomLayout sider={false} tracker={false} header={false}>
      <CloningPage />
      {/* <div>testing site for checking</div> */}
    </CustomLayout>
  );
}

export default ActionsRequired;
