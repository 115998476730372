import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
  Form,
  Badge,
  Col,
  Row,
  Upload,
  // Checkbox,
  // Input,
  Image,
  Collapse,
  message
  // Radio,
  // Select,
  // Divider,
  // AutoComplete,
  // notification
  // InputNumber,
  // Spin
} from 'antd';
import CustomButton from '@/common/CustomButton';
import upload from '../../images/empty.svg';
import Close from '../../images/Closesvg';
// import Pencil from '../../images/Pencilsvg';
import styles from './index.module.less';
import CustomCollapse from '@/common/CustomCollapse';
import {
  DownOutlined,
  HolderOutlined,
  PlusCircleFilled
} from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FormItem from 'common/FormItem';
import Delete from '../../images/Deletesvg';
import DisplayFiles from '../DisplayFiles';
import { blobToFile, removeDuplicatesFromArray } from 'utilities/helpers';
import { createPDF, mergePDF, pdfArrayToBlob } from 'pdf-actions';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
// import { AutoComplete } from 'antd';
import { Link } from 'react-router-dom';
import LocalStorage from 'utilities/localStorage';
// import { getLoggedInUser } from 'utilities/helpers';
// import AdminHeader from '../AdminHeader';
// import FormatDocs from '../FormatDocs';
// import { CaretDownFilled } from '@ant-design/icons';
// import { debounce } from 'lodash';
// import { log } from '@craco/craco/lib/logger';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// , useLocation
import { getclonebyid } from '../../redux/slice';
import {
  getAllApprovers,
  getAllstandardenv,
  getAllenvByID
} from '../../../initiate-envelope/redux/slice';

const { Panel } = Collapse;
const { Dragger } = Upload;
// const { Option } = Select;

const cloningPage = () => {
  const { cloning_data } = useSelector((state) => state.cloningdataList);
  const { allApprovers, customenv } = useSelector((state) => state.approvers);

  // const [emailMessage, setEmailMessage] = useState('');
  const [formValues, setformValues] = useState({});
  // const [setSignOrder] = useState(true);
  // signOrder,
  const [itemList, setItemList] = useState([{ id: 1, value: '', envtype: '' }]);
  const [setMergedPdfBlob] = useState(null);
  // mergedPdfBlob,
  const [selectedOption, setSelectedOption] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [approverSearch, setApproverSearch] = useState('');

  const dispatch = useDispatch();
  const params = useParams();
  // const location = useLocation();

  // const env_type = new URLSearchParams(location.search).get('type');
  // const env_mode = new URLSearchParams(location.search).get('mode');
  const env_type = cloning_data?.type;
  const env_mode = 'edit';
  console.log('customenv', customenv);

  useEffect(() => {
    if (env_type === 'custom' && env_type !== null) {
      // setSignOrder(true);
      // handleGetCustomEnv();
      handleGetStandardEnv();
    }
    if (env_type === 'standard' && env_type !== null) {
      // setSignOrder(true);
      handleGetStandardEnv();
    }
    if (env_mode === 'edit' && env_mode !== null) {
      // setSignOrder(true);
      handleGetEnv();
    }
  }, []);

  const handleGetEnv = () => {
    dispatch(getAllenvByID(params.id));
  };

  const handleGetStandardEnv = () => {
    const payload = {
      id: params.id
    };
    // console.log('payloadAPI', payload);
    dispatch(getAllstandardenv(payload));
  };

  useEffect(() => {
    if (
      // env_type === 'custom' ||
      // env_type === 'standard' ||
      // env_type === 'ad_hoc' ||
      env_mode === 'edit'
    ) {
      if (cloning_data?.envelope_approvers) {
        console.log(
          'Approvers data:',
          // customenv[0]?.designations[0]?.designation_mappings[0]?.user
          cloning_data?.envelope_approvers
        );
        let selectedOption = [];
        let itemList = [];
        cloning_data?.envelope_approvers.forEach((item) => {
          console.log('item_items', item?.envelope_approver);
          selectedOption = [
            ...selectedOption,
            {
              name: item?.envelope_approver?.display_name,
              email: item?.envelope_approver?.email,
              job_title: item?.envelope_approver?.job_title,
              approver_id: item?.id
            }
          ];
          itemList = [
            ...itemList,
            {
              id: item.id,
              value: item.email,
              envtype: item.id,
              approver_id: item?.id
            }
          ];
        });
        setSelectedOption(selectedOption);
        setItemList(itemList);
      }
    }
  }, [cloning_data]);

  console.log('cloning_data', cloning_data?.envelope_approvers);
  console.log('name_selectedOption', selectedOption);

  const mergePDFHandler = async () => {
    let files = [...fileList];
    const pdfDocs = [];

    for (let i = 0; i < files?.length; i++) {
      const file = files[i];
      const pdfFile = await createPDF.PDFDocumentFromFile(file);
      pdfDocs.push(pdfFile);
    }

    const mergedPdfFile = await (await mergePDF(pdfDocs)).save();
    const pdfBlob = pdfArrayToBlob(mergedPdfFile);
    const finalBlob = blobToFile(pdfBlob, 'adhoc_envelope_merged.pdf');

    setMergedPdfBlob(finalBlob);
  };

  // const onCheckBoxToggle = (e) => {
  //   e.target.checked;
  //   setSignOrder(e.target.checked);
  //   signOrder === false ? null : (
  //     <>
  //       {notification.destroy()}
  //       {notification.info({
  //         message: (
  //           <strong className="sfprotext-bold font-16">
  //             Do you want parallel approvals?
  //           </strong>
  //         ),
  //         description: (
  //           <p className="font-12">
  //             Unchecking this will send the envelope to all approvers
  //             simultaneously. Please keep it checked if you want the approvers
  //             to review the envelope sequentially.
  //           </p>
  //         ),
  //         placement: 'bottomLeft',
  //         duration: null
  //       })}
  //     </>
  //   );
  // };

  console.log('selectedOption', selectedOption);
  console.log('allApprovers', allApprovers);
  console.log('customenv', customenv);
  let selectedRows = [];
  let filtered_rows = allApprovers?.filter(function (cv) {
    return !selectedOption.find(function (e) {
      return e?.email === cv?.email;
    });
  });
  if (selectedOption.length) {
    filtered_rows?.map((item) => {
      selectedRows.push({
        name: item.display_name,
        email: item.email,
        id: item.id
      });
    });
  } else {
    allApprovers?.map((item) => {
      selectedRows.push({
        name: item.display_name,
        email: item.email,
        id: item.id
      });
    });
  }

  console.log('setSelectedOption112233', selectedOption);

  const addInput = () => {
    var highestValue = 0; //keep track of highest value
    // loop through array of objects
    for (var i = 0, len = itemList?.length; i < len; i++) {
      var value = Number(itemList[i]['id']);
      if (value > highestValue) {
        highestValue = value;
      }
    }
    let id = highestValue + 1;
    setItemList([...itemList, { id: id, value: '', envtype: '' }]);
  };

  // console.log('cloning_data', cloning_data);

  const approverPayload = useMemo(() => {
    return { type: 'users', search: approverSearch };
  }, [approverSearch]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(getAllApprovers(approverPayload));
    }, 500);

    // Cleanup the timeout to avoid unnecessary dispatch if the component unmounts or the dependency changes
    return () => clearTimeout(timeoutId);
  }, [dispatch, approverPayload]);

  const [form] = Form.useForm();

  // console.log('merged_document', cloning_data?.merged_document);
  useEffect(() => {
    if (cloning_data) {
      // Populate the form with the response data
      form.setFieldsValue({
        description: cloning_data?.title || '',
        emailmessage: cloning_data?.message || ''
      });
      // form.setFieldsValue({
      //   setFileList: cloning_data?.merged_document || ''
      // });
      // if (cloning_data?.merged_document) {
      //   const fileUrl = cloning_data.merged_document;
      //   // Convert the single URL into a file object
      //   setFileList([
      //     {
      //       uid: '1', // Unique identifier
      //       name: fileUrl.split('/').pop(), // Extract file name from URL
      //       status: 'done', // Indicate file is uploaded
      //       url: fileUrl // The URL of the document
      //     }
      //   ]);

      //   // setFileList(formattedFileList);
      // }
    }
  }, [cloning_data, form]);

  // console.log('setEmailMessage', emailMessage);
  // Load initial value from localStorage
  // useEffect(() => {
  //   const savedMessage = localStorage.getItem('emailmessage');
  //   if (savedMessage) {
  //     setEmailMessage(savedMessage);
  //     form.setFieldsValue({ emailmessage: savedMessage }); // Set the form field's initial value
  //   }
  // }, [form]);

  // Handle change in the TextArea
  const handleTextAreaChange = (e) => {
    const value = e.target.value;
    // setEmailMessage(value);
    localStorage.setItem('emailmessage', value); // Store the value in localStorage
    form.setFieldsValue({ emailmessage: value }); // Update the form field value
  };

  // const removeInput = (id) => {
  //   console.log('id', id);
  //   console.log(`Removing input at index: ${id}`);
  //   let itemValues = [...itemList];
  //   let selectedOptions = [...selectedOption];
  //   itemValues.splice(id, 1);
  //   selectedOptions.splice(id, 1);
  //   setItemList(itemValues);
  //   setSelectedOption(selectedOptions);
  // };

  const removeInput = (id) => {
    console.log('Before remove:', itemList, selectedOption);
    let itemValues = [...itemList];
    let selectedOptions = [...selectedOption];
    itemValues.splice(id, 1);
    selectedOptions.splice(id, 1);
    console.log('After remove:', itemValues, selectedOptions);
    setItemList(itemValues);
    setSelectedOption(selectedOptions);
  };

  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    var updatedList = [...selectedOption];
    var updatedItemList = [...itemList];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    const [reorderedItemList] = updatedItemList.splice(
      droppedItem.source.index,
      1
    );
    updatedItemList.splice(droppedItem.destination.index, 0, reorderedItemList);
    setItemList(updatedItemList);
    setSelectedOption(updatedList);
  };
  const handleOnChangePdfUpload = ({ fileList: onChangeFileList }) => {
    let files = fileList.concat(onChangeFileList.map((o) => o.originFileObj));

    setFileList(removeDuplicatesFromArray(files, 'name'));
  };

  const handleOnDropPdfUpload = (e) => {
    let files = fileList.concat(e.dataTransfer.files);
    setFileList(removeDuplicatesFromArray(files, 'name'));
  };

  const handleNext = () => {
    // let err = 0;
    // if (
    //   description === '' ||
    //   description === undefined ||
    //   approvers === '' ||
    //   approvers === undefined
    //   emailmessage === '' ||
    //   emailmessage === undefined
    // ) {
    //   err = 1;
    // }
    // if (err === 1) {
    //   // return message.error('Please enter all required fields');
    // }
    setformValues({
      //   title: localStorage.getItem('title'),
      //   plant_id: localStorage.getItem('plant_id'),
      //   department_id: localStorage.getItem('department_id'),
      description: form.getFieldValue('description')
      //   approvers: form.getFieldValue('approvers'),
      //   message: form.getFieldValue('emailmessage'),
      //   emailmessage: form.getFieldValue('emailmessage'),
      //   category_id: localStorage.getItem('category_id'),
      //   sub_category_id: localStorage.getItem('sub_category_id'),
      //   nfa_amount: localStorage.getItem('nfa_amount'),
      //   balance_amount: localStorage.getItem('balance_amount'),
      //   activity: localStorage.getItem('activity'),
      //   io_no_id: localStorage.getItem('io_no_id'),
      //   io_amount: localStorage.getItem('io_amount'),
      //   io_balance_amount: localStorage.getItem('io_balance_amount'),
      //   cost_centr_id: localStorage.getItem('cost_centr_id'),
      //   cost_center_amount: localStorage.getItem('cost_center_amount'),
      //   cost_center_balance_amount: localStorage.getItem(
      //     'cost_center_balance_amount'
      //   )
    });

    //
    if (selectedOption?.length > 0 && fileList?.length > 0) {
      mergePDFHandler();
      // setCurrent(current + 1);
    } else {
      return message.error(
        'Please select atleast one document and one approver'
      );
    }
  };

  const onSelect = (data) => {
    console.log('data123', data);

    setSelectedOption([
      ...selectedOption,
      // { name: data?.display_name, email: data?.email }
      { name: data?.name, email: data?.email, approver_id: data?.id }
      // { name: display_name, email: email, id: selectedItem?.id }
    ]);
  };
  console.log('selectedOption', selectedOption);

  useEffect(() => {
    const { description, approvers, message } = formValues ? formValues : {};
    form.setFieldsValue({
      description: description,
      approvers: approvers,
      message: message
    });
  }, []);

  const removeInputFields = useCallback(
    (e, index) => {
      const rows = [...fileList];
      rows.splice(index, 1);
      setFileList(rows);
    },
    [fileList]
  );
  const handleApproverSearch = (text) => {
    setApproverSearch(text);
  };
  // console.log('customenv[0]?.title', customenv[0]);

  // useEffect(() => {
  //   console.log('customenv123', env_type);
  //   env_type != null && (env_type === 'standard' || env_type === 'custom')
  //     ? (() => {
  //         form.setFieldsValue({
  //           envelope_name: customenv[0]?.title
  //             ? customenv[0]?.title
  //             : localStorage.getItem('title'),
  //           'Sub-Category': customenv?.sub_category_id,
  //           category_id: customenv[0]?.category_id
  //         });
  //       })()
  //     : form.setFieldsValue({
  //         // description: localStorage.getItem('description'),
  //         envelope_name: localStorage.getItem('envelope_name')
  //       });
  // }, [env_type, customenv]);

  // useEffect(() => {
  //   customenv?.envelope_type === 'standard' &&
  //   customenv?.envelope_type === 'custom'
  //     ? `${
  //         (localStorage.setItem('description', customenv?.description),
  //         localStorage.setItem('envelope_name', customenv?.title))
  //       }`
  //     : '';
  // }, []);

  const zInd = 998;
  const user = LocalStorage.getItem('gnfa_user');

  const Header = () => {
    return (
      <>
        {/* {user?.user_details.role === 'admin1' ? (
          <AdminHeader
            project={project}
            handleSave={handleSave}
            edit_env={edit_env}
            // handleEdit={handleEdit}
            envtype={envtype}
            type={type}
            projects={projects}
            projectId={projectId}
            customenv={customenv}
          />
        ) : ( */}
        <div className={`w-100 ${styles.init_env_header}`}>
          <Row>
            <Link to="/dashboard">
              <div className={`df-jc-ac ${styles.x_button}`}>
                <Close />
              </div>
            </Link>
            <Col span={19} className={`${styles.header_text}`}>
              {/* <div className={`w-100 ${styles.init_env_header}`}> */}
              {/* `${localStorage.getItem('title')}`</div> */}
              <p className={styles.HeaderStyles}>
                {' '}
                {cloning_data?.type} envelope{' '}
              </p>
            </Col>

            <Col className="justify-content-end df-jb-ac" span={4}>
              {/* {customenv?.Documents?.length > 0 ? (
                <CustomButton
                  style={{ marginRight: '12px' }}
                  onClick={() => setFormatModal(true)}
                >
                  Formats
                </CustomButton>
              ) : (
                ''
              )} */}
              <CustomButton
                type="primary"
                onClick={(e) => {
                  handleNext(e);
                }}
              >
                Next
              </CustomButton>
            </Col>
          </Row>
        </div>
        {/* )} */}
      </>
    );
  };
  // console.log('checkedenv_type', env_type);

  // let chcked = env_type === 'custom' ? true : false;
  const formatResult = (item) => {
    console.log('item', item);
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>
          {item?.name} ({item?.email})
        </span>
      </>
    );
  };

  // console.log('role', user?.user_details?.role);

  useEffect(() => {
    handleGetCloneData();
  }, []);

  const handleGetCloneData = () => {
    let payload = {
      id: params.id
    };
    dispatch(getclonebyid(payload));
  };

  return (
    <>
      {/* {envelopeDataLoading ? ( */}
      {/* <div
        style={{ height: '100vh', flexDirection: 'column' }}
        className="df-jc-ac h-100"
      >
        {' '}
        <Spin />
        Please wait
      </div> */}
      {/* ) : ( */}
      <div className={`${styles.mainContainer}`}>
        <Form
          form={form}
          layout="vertical"
          className={`ant-form ant-form-vertical  ${styles.form_width}`}
        >
          <Header />
          <div className={`${styles.container}`}>
            {user?.user_details?.role === 'user' ||
            user?.user_details?.role === 'admin' ||
            user?.user_details?.role === 'approver' ? (
              <>
                <CustomCollapse
                  expandIcon={({ isActive }) => (
                    <DownOutlined rotate={isActive ? 180 : 0} />
                  )}
                  expandIconPosition="end"
                  bordered={false}
                  defaultActiveKey={['1']}
                >
                  <Panel
                    header={
                      <Badge
                        className={`${styles.badge}`}
                        count={1}
                        dot={false}
                      />
                    }
                    extra="Add envelope details"
                    key="1"
                  >
                    <Row
                      className="mt-4"
                      gutter={{
                        xs: 16,
                        sm: 32,
                        md: 30,
                        lg: 48
                      }}
                    >
                      <Col className="gutter-row" xs={24} xl={24}>
                        <FormItem
                          label="Description"
                          name="description"
                          rules={[
                            {
                              required: true,
                              message: 'Enter Description'
                            }
                          ]}
                        >
                          <TextArea placeholder="Enter description "></TextArea>
                        </FormItem>
                      </Col>
                    </Row>
                  </Panel>
                </CustomCollapse>

                <br />

                {/* Upload Documents */}
                <CustomCollapse
                  expandIcon={({ isActive }) => (
                    <DownOutlined rotate={isActive ? 180 : 0} />
                  )}
                  expandIconPosition="end"
                  bordered={false}
                  defaultActiveKey={['1']}
                >
                  <Panel
                    header={
                      <Badge
                        className={`${styles.badge}`}
                        count={2}
                        dot={false}
                      />
                    }
                    extra="Upload documents"
                    key="2"
                  >
                    {fileList?.length ? (
                      <DisplayFiles
                        key={fileList.name}
                        files={fileList}
                        handleOnChangePdfUpload={handleOnChangePdfUpload}
                        handleOnDropPdfUpload={handleOnDropPdfUpload}
                        removeInputFields={removeInputFields}
                      />
                    ) : (
                      <Dragger
                        // name="file"
                        name="documets"
                        accept=".pdf"
                        listType="picture-card"
                        fileList={fileList} // Bind the dynamic file list
                        className={`avatar-uploader ${styles.upload}`}
                        onChange={handleOnChangePdfUpload}
                        onDrop={handleOnDropPdfUpload}
                        customRequest={({ onSuccess }) => {
                          setTimeout(() => {
                            onSuccess('ok');
                          }, 0);
                        }}
                        multiple={true}
                        showUploadList={false}
                      >
                        <div className="p-3">
                          <div className={`${styles.upgrade}`}>
                            <Image
                              className={`df-jb-ac ${styles.image}`}
                              src={upload}
                              preview={false}
                            />
                          </div>

                          <div className={`${styles.upgrade}`}>
                            <span>Drag and drop your files here</span>
                          </div>
                          <div className={`mb-2 ${styles.upgrade}`}>
                            <span>or</span>
                          </div>
                          <div className={`${styles.upgrade}`}>
                            <CustomButton type="primary">Upload</CustomButton>
                          </div>
                        </div>
                      </Dragger>
                    )}
                  </Panel>
                </CustomCollapse>
              </>
            ) : (
              ''
            )}
            <br />

            {/* Add Approvers Code */}
            <CustomCollapse
              expandIcon={({ isActive }) => (
                <DownOutlined rotate={isActive ? 180 : 0} />
              )}
              expandIconPosition="end"
              bordered={false}
              defaultActiveKey={['1']}
            >
              <Panel
                header={
                  <Badge
                    className={`${styles.badge}`}
                    count={user?.user_details.role === 'user' ? 3 : 1}
                    dot={false}
                  />
                }
                extra={
                  env_type === 'standard' ? 'Approvers List' : 'Add approvers'
                }
                key="3"
              >
                {/* {env_type !== 'standard' ? (
                  <div>
                    <Checkbox
                      className={`font-14`}
                      onChange={onCheckBoxToggle}
                      defaultChecked={chcked}
                      checked={signOrder}
                    >
                      Enable set signing order
                    </Checkbox>
                  </div>
                ) : (
                  ''
                )} */}
                <br />
                <DragDropContext onDragEnd={handleDrop}>
                  <Droppable
                    droppableId="list-container"
                    className={`${styles.approver_line}`}
                  >
                    {(provided) => (
                      <div
                        className="list-container"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {itemList.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={`${item.id}`}
                            index={index}
                          >
                            {(provided) => (
                              <>
                                <div
                                  className={
                                    env_type === 'standard'
                                      ? `item-container ${styles.nodragndrop}`
                                      : 'item-container'
                                  }
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                >
                                  <Row
                                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                                  >
                                    <Col className="gutter-row" span={1}>
                                      <div
                                        className={`
                                        ${
                                          item.ind > 1
                                            ? `${styles.step} ${styles.v_stepper}${styles.circle} `
                                            : ''
                                        } mt-2`}
                                      >
                                        <div className={`${styles.circle}`}>
                                          <p>{index + 1}</p>
                                        </div>
                                        <div
                                          className={
                                            itemList.length !== index + 1
                                              ? `${styles.line}`
                                              : ''
                                          }
                                        ></div>
                                      </div>
                                    </Col>
                                    {env_type !== 'standard' ? (
                                      <Col className="gutter-row" span={1}>
                                        <div className="mt-2">
                                          <HolderOutlined />
                                        </div>
                                      </Col>
                                    ) : (
                                      ''
                                    )}
                                    <Col className="gutter-row" xs={17} xl={16}>
                                      <div
                                        className={
                                          item.envtype !== ''
                                            ? `${styles.approverdisIndex}`
                                            : `${styles.approverIndex}`
                                        }
                                      >
                                        <FormItem
                                          {...item}
                                          rules={[
                                            {
                                              required: true,
                                              whitespace: true,
                                              message: 'This field is required'
                                            }
                                          ]}
                                          name="approvers"
                                          noStyle
                                          className="aasdsad"
                                        >
                                          <ReactSearchAutocomplete
                                            fuseOptions={{
                                              keys: ['name', 'email']
                                            }}
                                            onSearch={(e) =>
                                              handleApproverSearch(e)
                                            }
                                            inputDebounce={300}
                                            items={selectedRows}
                                            showIcon={false}
                                            formatResult={formatResult}
                                            placeholder="Select Approver"
                                            onSelect={onSelect}
                                            autocomplete="on"
                                            // showClear={false}
                                            showClear
                                            styling={{
                                              height: '34px',
                                              border: '1px solid darkgreen',
                                              borderRadius: '4px',
                                              backgroundColor: 'white',
                                              boxShadow: 'none',
                                              fontSize: '12px',
                                              clearIconMargin: '3px 8px 0 0',
                                              zIndex: `${zInd - index}`
                                            }}
                                            inputSearchString={
                                              selectedOption[index]
                                                ? selectedOption[index]
                                                    .job_title
                                                  ? selectedOption[index]
                                                      .display_name +
                                                    ' - (' +
                                                    selectedOption[index]
                                                      .job_title +
                                                    ')' +
                                                    ' (' +
                                                    selectedOption[index]
                                                      .email +
                                                    ')'
                                                  : selectedOption[index].name +
                                                    ' (' +
                                                    selectedOption[index]
                                                      .email +
                                                    ')'
                                                : ''
                                            }
                                          />
                                        </FormItem>
                                      </div>
                                    </Col>
                                    {/* <Col className="gutter-row" span={4}>
                                    <div className="mt-2">
                                      <b>Needs to sign</b>
                                    </div>
                                  </Col> */}
                                    {item.value === '' ? (
                                      <span onClick={() => removeInput(index)}>
                                        <Delete
                                          className={`cursor-pointer ${styles.delete_icon}`}
                                        />
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                  </Row>
                                </div>
                                <br />
                              </>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  {env_type !== 'standard' ? (
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col className="gutter-row" span={4}>
                        <div type="button" onClick={() => addInput()}>
                          <span className={`cursor-pointer ${styles.span_add}`}>
                            <PlusCircleFilled />
                            {'\u00a0'} Add Approvers
                          </span>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    ''
                  )}
                </DragDropContext>

                {/*  */}
              </Panel>
            </CustomCollapse>
            <br />
            {user?.user_details?.role === 'user' ||
            user?.user_details?.role === 'admin' ? (
              <>
                <CustomCollapse
                  expandIcon={({ isActive }) => (
                    <DownOutlined rotate={isActive ? 180 : 0} />
                  )}
                  expandIconPosition="end"
                  bordered={false}
                >
                  <Panel
                    className={` ${styles.panel}`}
                    header={
                      <Badge
                        className={` ${styles.badge}`}
                        count={4}
                        dot={false}
                      />
                    }
                    extra="Add message"
                    key="4"
                  >
                    <FormItem
                      label="Add messsage"
                      name="emailmessage"
                      rules={[
                        {
                          required: false,
                          message: 'Enter Total value'
                        }
                      ]}
                    >
                      <TextArea
                        className="gutter-row"
                        xs={24}
                        xl={24}
                        placeholder={'Enter message'}
                        // value={emailMessage} // Bind the state to the value
                        onChange={handleTextAreaChange}
                      />
                    </FormItem>
                  </Panel>
                </CustomCollapse>
              </>
            ) : (
              ''
            )}
            <br />
          </div>
        </Form>
      </div>
      {/* )} */}
    </>
  );
};

export default cloningPage;
