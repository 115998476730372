import Header from '@/common/Content/header';
import SearchBar from '@/common/Content/searchBar';
import CustomButton from '@/common/CustomButton';
import {
  BackwardFilled,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  // DownOutlined,
  ForwardFilled,
  // LoadingOutlined,
  PauseOutlined,
  StopOutlined
} from '@ant-design/icons';

import {
  Badge,
  Menu,
  Popover,
  Table,
  Col,
  Row,
  Progress,
  Timeline,
  Tooltip,
  Modal
} from 'antd';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import { getActionsRequired } from '../../redux/slice';
import {
  getTrackersList,
  getAllData,
  getHistoryList,
  getSearchList
} from '../../../admin-manage/redux/slice';
import styles from './index.module.less';
import viewshape from '../../images/viewshape.svg';
import AuditlogVector from '../../images/AuditlogVector.svg';
import DownloadVector from '../../images/DownloadVector.svg';
import moment from 'moment';
import MessageIcon from '../../images/shape.svg';
import { getLoggedInUser } from 'utilities/helpers';

const ActionsRequiredPage = ({ selectedStatus, setSelectedStatus }) => {
  const gcd = function (a, b) {
    if (b < 0.0000001) return a; // Since there is a limited precision we need to limit the value.

    return gcd(b, Math.floor(a % b)); // Discard any fractions due to limitations in precision.
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'approved':
        return '#52C41A'; // Green for approved
      case 'hold':
        return '#FFA500'; // Orange for need clarification
      case 'rejected':
        return '#F5222D'; // Red for rejected
      default:
        return '#1890FF'; // Default blue for other statuses
    }
  };
  const user = getLoggedInUser();
  const current_id = user?.user_details?.id;
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [search, setSearch] = useState('');
  const handleHistory = (id) => {
    setShowHistoryModal(true);
    dispatch(getHistoryList(id));
  };

  const current_status =
    window?.location?.pathname?.replace('/', '') === 'pending'
      ? 'waiting for others'
      : window?.location?.pathname?.replace('/', '') === 'approved'
      ? 'completed'
      : window?.location?.pathname?.replace('/', '') === 'cancelled'
      ? 'Cancelled'
      : window?.location?.pathname?.replace('/', '') === 'information'
      ? 'information'
      : window?.location?.pathname?.replace('/', '') === 'rejected'
      ? 'my_rejected'
      : window?.location?.pathname?.replace('/', '');

  const onCancel = () => {
    setShowHistoryModal(false);
    let payload = {};

    if (search !== '') {
      payload[current_status === 'information' ? 'search_cat' : 'search'] =
        search;
    }

    if (current_status !== 'manage' && current_status !== 'information') {
      payload['status'] = current_status;
    }

    if (current_status === 'information') {
      payload['user_id'] = current_id;
    }

    dispatch(getTrackersList(payload));
  };

  const columns = [
    {
      key: 'as',
      title: '',
      dataIndex: 'icons',
      render: (test, tesa) => {
        return (
          <>
            <div className={`${styles.table_icon}`}>
              {tesa.status === 'cancelled' ? (
                <StopOutlined style={{ color: 'red' }} />
              ) : tesa.status === 'Waiting for others' ? (
                <ClockCircleOutlined />
              ) : tesa.status === 'rejected' ? (
                <CloseCircleOutlined style={{ color: '#F5222D' }} />
              ) : tesa.status === 'completed' || tesa.status === 'approved' ? (
                <CheckCircleOutlined style={{ color: '#52C41A' }} />
              ) : tesa.status === 'need clarification' ||
                tesa.status === 'hold' ? (
                <PauseOutlined style={{ color: 'blue' }} />
              ) : tesa.status === 'forwarded' ? (
                <ForwardFilled style={{ color: 'blue' }} />
              ) : tesa.status === 'returned' ? (
                <BackwardFilled style={{ color: 'blue' }} />
              ) : tesa.status === 'approved' ? (
                <CheckCircleOutlined style={{ color: '#52C41A' }} />
              ) : (
                <ClockCircleOutlined />
              )}
            </div>
          </>
        );
      }
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: '10%',
      render: (type) => (
        <div style={{ textTransform: 'capitalize' }}>{type}</div>
      )
    },
    // {
    //   title: 'Subject',
    //   dataIndex: 'subject',
    //   key: 'id',
    //   width: '40%',
    //   render: (subject) => (
    //     <>
    //       <div className="mb-1">
    //         <b className="font-14 ">{subject.title}</b>
    //       </div>
    //     </>
    //   )
    // },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      width: '40%',
      // render: renderSubject,
      render: (subject, record) => (
        <>
          <div className="mb-1">
            <b className="font-14 ">
              <a
                href={`actions-required/review-taken-actions/${record.id}`}
                target="__blank"
              >
                {subject.title}
                {`# Env-${
                  record['envelope_flow.envelope_flows_id']
                    ? record['envelope_flow.envelope_flows_id']
                    : record?.id
                  // record?.envelope_flows_id
                }`}
              </a>
            </b>
          </div>
          <div>
            To:{' '}
            {/* {subject?.accepters?.length
              ? subject?.accepters?.length > 2
                ? subject.accepters.slice(0, 2).map((item) => item.name)
                    .join`, `
                : subject.accepters.map((item) => item.name).join`, `
              : ''} */}
            {subject?.envelope_approvers
              ? subject?.envelope_approvers
                ? subject?.envelope_approvers
                    // .slice(0, 5)
                    .map(
                      (item) =>
                        `${
                          item.envelope_approver.first_name
                            ? item.envelope_approver.first_name
                            : ''
                        } ${
                          item.envelope_approver.last_name
                            ? item.envelope_approver.last_name
                            : ''
                        }`
                      //  {`${item.envelope_approver.first_name} ${item.envelope_approver.last_name}`}
                    )
                    .join(', ')
                : subject.envelope_approvers
                    .map((item) =>
                      // `${item.envelope_approver.first_name} ${item.envelope_approver.last_name}`
                      {
                        `${
                          item.envelope_approver.first_name
                            ? item.envelope_approver.first_name
                            : ''
                        } ${
                          item.envelope_approver.last_name
                            ? item.envelope_approver.last_name
                            : ''
                        }`;
                      }
                    )
                    .join(', ')
              : ''}
            {'\u00a0'}
            {record?.project_name ? `${' || '}${record?.project_name}` : ''}
            <Popover
              content={
                <>
                  <h2 className="sfprotext-bold">Approvers</h2>
                  {subject?.envelope_approvers
                    ? subject.envelope_approvers.map((item, index) => (
                        <p
                          key={index}
                        >{`${item.envelope_approver.first_name} ${item.envelope_approver.last_name}`}</p>
                      ))
                    : ''}
                </>
              }
              placement="rightTop"
            >
              {subject?.envelope_approvers ? (
                subject.envelope_approvers > 2 ? (
                  <Badge
                    className={`site-badge-count-109 ${styles.badge}`}
                    count={`+ ${subject.envelope_approvers.length - 2}`}
                  />
                ) : (
                  ''
                )
              ) : (
                ''
              )}
            </Popover>
          </div>
        </>
      )
    },
    {
      title: 'Status',
      key: 'description',
      dataIndex: 'description',
      width: '30%',
      render: (description) => (
        <>
          <Row>
            <Col span={18}>
              <div className="mr-5 mb-1">
                <h1>
                  <Progress
                    size="small"
                    // success={{ percent: 30 }}
                    percent={`${Math.floor(
                      (description.present_approvers_count /
                        description.total_approvers_count) *
                        100
                    )}`}
                    // strokeColor={'#1CB697'}
                    strokeColor={getStatusColor(description.status)}
                    status="active"
                    format={gcd}
                    showInfo={false}
                  />
                </h1>
                <div>
                  {description.status === 'Accepted'
                    ? 'Approved'
                    : description.status === 'Hold'
                    ? 'Need Clarification'
                    : description.status}
                </div>
              </div>
            </Col>
            <Col
              span={6}
              style={{
                marginLeft: '-40px',
                fontSize: '13px',
                marginTop: '3px'
              }}
            >{`${description.present_approvers_count}/${description.total_approvers_count} done`}</Col>
          </Row>
        </>
      )
    },
    // {
    //   title: 'Actions',
    //   dataIndex: 'actions',
    //   key: 'id',
    //   width: '20%',
    //   render: (actions) => {
    //     return (
    //       <>
    //         <Link to={'review-actions/' + `${actions.id}`}>
    //           <CustomButton type="primary" className={`${styles.use_button}`}>
    //             {'Review'}
    //           </CustomButton>
    //         </Link>
    //       </>
    //     );
    //   }
    // }
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '20%',
      render: (text, record) => {
        return (
          <>
            {window?.location?.pathname?.replace('/', '') === 'hold' ? (
              <Link to={'/actions-required/review-actions/' + `${record.id}`}>
                <CustomButton type="primary" className={`${styles.use_button}`}>
                  {'Release'}
                </CustomButton>
              </Link>
            ) : window?.location?.pathname?.replace('/', '') ===
              'actions-required1' ? (
              <CustomButton className={`${styles.use_button1} mr-2`}>
                <a href={record.document_url} download>
                  Download
                </a>
              </CustomButton>
            ) : (
              <>
                {record.status === 'approved' ? (
                  <CustomButton className={`${styles.down_button} mr-21`}>
                    <a href={record.merged_document} download target="__blank">
                      {/* Download */}
                      <img src={DownloadVector} alt="Download" />
                    </a>
                  </CustomButton>
                ) : (
                  ''
                )}
                <CustomButton className={`${styles.use_button}`}>
                  <a
                    href={`/actions-required/review-taken-actions/${record.id}`}
                    target="__blank"
                  >
                    {/* View */}
                    <img src={viewshape} />
                  </a>
                </CustomButton>
                <CustomButton className={`${styles.down_button}`}>
                  {/* <a
                    href={`/actions-required/review-taken-actions/${record.id}`}
                    target="__blank"
                  >
                    <img src={AuditlogVector} />
                  </a> */}
                  <span onClick={() => handleHistory(record.id)}>
                    {' '}
                    <img src={AuditlogVector} />{' '}
                  </span>
                </CustomButton>
              </>
            )}
          </>
        );
      }
    }
  ];
  // const [setSearch] = useState('');
  // search,
  const { all_data, history_data } = useSelector((state) => state.trackersList);
  // state.actionsList
  // all_actions
  const all_actions = all_data;
  // console.log('all_actions', all_actions);
  // console.log('history_data', history_data);
  const data = all_actions?.map((item) => {
    // const { accepters, name, id, created_by, title } = item;
    const {
      accepters,
      created_by,
      status,
      total_approvers_count,
      present_approvers_count,
      title,
      type,
      envelope_approvers,
      id
    } = item;
    //   return {
    //     ...item,
    //     subject: {
    //       id,
    //       name,
    //       created_by,
    //       title
    //     },
    //     description: {
    //       id,
    //       accepters: accepters
    //     },
    //     actions: {
    //       id
    //     }
    //   };
    // });
    return {
      ...item,
      subject: {
        // accepters: JSON.parse(accepters),
        accepters: accepters,
        envelope_approvers: envelope_approvers,
        created_by,
        title,
        type,
        id
      },
      description: {
        total_approvers_count,
        status,
        present_approvers_count
      },
      actions: {
        id
      }
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    handleGetStats();
  }, [search]);
  // const handleGetStats = () => {
  //   let payload = search !== '' ? { search: search } : {};
  //   dispatch(getActionsRequired(payload));
  // };
  // useEffect(() => {
  //   handleAllData();
  // }, []);

  useEffect(() => {
    handleAllData();
  }, []);
  const handleGetStats = () => {
    let payload = { type: 'Initiated By Others', category: 'approved' };

    if (search !== '') {
      payload[current_status === 'information' ? 'search_cat' : 'search'] =
        search;
    }

    if (current_status !== 'manage' && current_status !== 'information') {
      payload['status'] = current_status;
    }

    if (current_status === 'information') {
      payload['user_id'] = current_id;
    }

    // dispatch(getTrackersList(payload));
    // console.log('payload', payload);
    dispatch(getSearchList(payload));
    // dispatch(getTrackersList());
  };

  const handleAllData = () => {
    let payload = {
      type: 'Initiated By Others',
      category: 'approved'
    };
    dispatch(getAllData(payload));
    dispatch(getTrackersList(payload));
    // dispatch(getActionsRequired(payload));
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const statusesData = [
    { label: 'All', status: '' },
    { label: 'Open', status: 'open' },
    { label: 'Deleted', status: 'deleted' },
    { label: 'Assigned', status: 'Assigned' }
  ];

  const statusMenu = (
    <Menu>
      {statusesData.map((item, index) => (
        <Menu.Item
          key={index}
          onClick={() => {
            if (selectedStatus?.label === item.label) {
              setSelectedStatus(null);
            } else {
              setSelectedStatus(item);
            }
          }}
        >
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <div
        className={`mb-4 ${styles.MRS_container}`}
        style={{
          display: 'flex',
          flexDirection: 'row'
          // alignItems: 'center',
          // gap: '16px'
          // flexWrap: 'nowrap'
          // alignContent: 'space-between',
          // justifyContent: 'center'
        }}
      >
        <Header
          justify="space-between"
          align="middle"
          title="Approved by me"
          buttons={[]}
          style={{ width: '100%' }}
        />
        <div style={{ width: '420%', paddingLeft: '0px !important' }}>
          <SearchBar
            justify="end"
            align="middle"
            className="mt-43"
            gutter={32}
            inputProps={{
              placeholder: 'Search by envelope name'
            }}
            // filters={true}
            statusMenu={statusMenu}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <div>
        <Table
          rowKey={(obj) => obj.id}
          className={`${styles.custom_table}`}
          columns={columns}
          dataSource={data}
        />
      </div>
      {showHistoryModal ? (
        <Modal
          title="Audit Trail"
          visible={showHistoryModal}
          onCancel={onCancel}
          footer={false}
        >
          <Timeline>
            {history_data.map((item, index) => {
              return (
                <Timeline.Item
                  key={index}
                  color={
                    item.status === 'rejected'
                      ? 'red'
                      : item.status === 'approved'
                      ? 'green'
                      : item.status === 'cancelled'
                      ? 'red'
                      : 'blue'
                  }
                >
                  {item?.user.display_name}
                  {item?.action_taken !== null ? (
                    <div className={`${styles.content_text}`}>
                      <span style={{ fontSize: '9px' }}>{`${
                        item?.action_taken === 'approved'
                          ? 'Approved'
                          : item.status === 'rejected'
                          ? 'Rejected '
                          : item?.action_taken
                      } on ${moment(item.action_taken_date).format(
                        'DD MMM YY, h:mm A'
                      )}`}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  {item?.comments !== null ? (
                    <>
                      <div className={`${styles.content_text}`}>
                        <span style={{ fontSize: '9px' }}>
                          <img
                            src={MessageIcon}
                            className={`${styles.image_size}`}
                          />
                        </span>
                        <span style={{ marginLeft: '4px', fontSize: '8px' }}>
                          <Tooltip placement="top" title={item?.comments}>
                            {item?.comments?.length > 40
                              ? item?.comments.substring(0, 40) + '...'
                              : item?.comments}
                          </Tooltip>
                        </span>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </Timeline.Item>
              );
            })}
          </Timeline>
        </Modal>
      ) : (
        ''
      )}
    </>
  );
};

export default ActionsRequiredPage;
