import { configureStore } from '@reduxjs/toolkit';
import loginReducer from 'pages/login/redux/slice';
import dashboardReduce from 'pages/dashboard/redux/slice';
import approversReducer from 'pages/initiate-envelope/redux/slice';
import trackersReducer from 'pages/admin-manage/redux/slice';
import reviewActionsReducer from 'pages/review-actions/redux/slice';
import settingsReducer from 'pages/settings/redux/slice';
import cloningReducer from 'pages/cloning-data/redux/slice';

export const store = configureStore({
  reducer: {
    login: loginReducer,
    dashboard: dashboardReduce,
    approvers: approversReducer,
    trackersList: trackersReducer,
    reviewActions: reviewActionsReducer,
    settings: settingsReducer,
    cloningdataList: cloningReducer
    // Define your reducers here
    // Learn more here: https://redux.js.org/tutorials/fundamentals/part-3-state-actions-reducers
  }
});
