import React, { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  // Radio,
  Row,
  Select,
  message
} from 'antd';
import styles from './index.module.less';
import {
  CaretDownFilled,
  CaretDownOutlined,
  DeleteOutlined,
  HolderOutlined,
  PlusCircleFilled,
  // DeleteOutlined,
  // HolderOutlined,
  // PlusCircleFilled,
  RightOutlined
} from '@ant-design/icons';
import FormItem from '@/common/FormItem';
import CustomLayout from '@/common/Layout';
import { useDispatch, useSelector } from 'react-redux';
// import { getAllSubCategories } from '@/features/initiate-envelope/redux/slice';
// import { getAllCategories } from '@/features/dashboard/redux/slice';
import {
  // createDoaFlow,
  editDoaFlow,
  // getAllApproversList,
  getAllbus,
  getAllDesignationsList,
  // getAllDoaList,
  get_DoaList_byid,
  getAllProjects,
  getAllSbgs
} from '@/pages/settings/redux/slice';
import { debounce } from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import { getLoggedInUser } from 'utilities/helpers';
import { useParams } from 'react-router-dom';
import { history } from '@/app/history';
// import Draggable from 'react-draggable';
import {
  getPlants,
  getfunctions,
  getcategory,
  getsubCategory,
  getactivity
} from '../../../../dashboard/redux/slice';
// import { getAllUsers } from '../../../../settings/redux/slice';
import { getAllDesignations } from '../../../redux/slice';

const { Option } = Select;
const EditDOAPage = () => {
  // const user = getLoggedInUser();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // const { subcategories, categories } = useSelector((state) => state.approvers);
  const { getdoaDetails_id } = useSelector(
    // all_approvers, all_designations,
    (state) => state.settings
  );

  const { get_designationDetails, all_Users } = useSelector(
    (state) => state.settings
  );
  console.log('all_Users', all_Users);
  const {
    get_plants,
    get_functions,
    get_category,
    get_subcategorylist,
    get_activity
  } = useSelector((state) => state.dashboard);

  console.log('getdoaDetails_id', getdoaDetails_id);

  const [flowDetails, setFlowDetails] = useState({});
  // const [categoryId, setCategoryId] = useState();
  //const [page, setPage] = useState(1);
  // const [search, setSearch] = useState('');
  const [sbuSearch, setSbuSearch] = useState('');
  const [projectSearch, setProjectSearch] = useState('');
  const [designSearch, setDesignSearch] = useState('');
  const [subSearch, setSubSearch] = useState('');
  const [categorySearch, setCategorySearch] = useState('');
  // const [isProjectActive, setIsProjectActive] = useState(null);
  const [anyValue, setAnyValue] = useState();
  const params = useParams();
  const [rowErrors, setRowErrors] = useState({}); // Track errors for each row
  const [filteredUsersByRow, setFilteredUsersByRow] = useState({}); // Track filtered users for each row
  const [designationSearch, setDesignationSearch] = useState('');
  const [updatedFormValues, setUpdatedFormValues] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null); // To store the selected sub-category name

  const [formValues, setFormValues] = useState([
    { sequence: 1, user_id: '', designation_id: '' }
  ]);

  console.log('updatedFormValues', updatedFormValues);
  const toggleCheckbox = (e) => {
    if (e.target.checked) {
      form.setFieldsValue({ min_value: 999, max_value: 999 });
    } else {
      form.setFieldsValue({ min_value: undefined, max_value: undefined });
    }
    setAnyValue(e.target.checked);
    console.log(e.target.checked);
  };
  console.log('anyValue', anyValue);
  const handleGetDoADetails = () => {
    let payload = { id: params.id };
    dispatch(get_DoaList_byid(payload)).then((res) => {
      if (res.payload.success) {
        setFlowDetails(res.payload.data);
      }
    });
  };

  console.log('flowDetails', flowDetails);

  const debouncedhandleDesignationSearchChange = debounce((e) => {
    setDesignationSearch(e);
  }, 600);

  const handleDesignationSearchChange = (e) => {
    debouncedhandleDesignationSearchChange(e); // Call the debounced function
  };

  useEffect(() => {
    if (designationSearch !== '') {
      let payload = { search: designationSearch }; // Adjust payload as needed
      console.log('Fetching with payload:', payload);
      // Fetch designations with debouncing (optional)
      const debouncedFetch = debounce(handlegetDesignations, 300);
      debouncedFetch(payload);
    }
  }, [designationSearch]);

  useEffect(() => {
    handleGetDoADetails();
    dispatch(getAllSbgs());
  }, []);

  // const handleSubSearch = (e) => {
  //   setSubSearch(e);
  // };

  const handleCategorySearch = (e) => {
    setCategorySearch(e);
  };

  const handleGetSubCategories = (id) => {
    const payload =
      subSearch != ''
        ? { sub_search: subSearch, category_id: id }
        : { category_id: id };
    // dispatch(getAllSubCategories(payload));
    dispatch(getsubCategory(payload?.category_id));
  };

  // const handleGetSubCategories = (id) => {
  //   // let payload =
  //   //   search !== '' ? { search: search } : { search: search, page: page };
  //   let payload = {
  //     subSearch: subSearch,
  //     // page: page,
  //     user: 'admin', // Add the user property here
  //     category_id: id
  //   };
  //   if (subSearch === '') {
  //     delete payload.search; // Remove `search` if it's not needed
  //   }
  //   dispatch(getAllSubCategories(payload));
  // };

  useEffect(() => {
    handleGetSubCategories();
  }, []);

  useEffect(() => {
    const payload =
      subSearch != ''
        ? {
            sub_search: subSearch,
            category_id: flowDetails?.category?.id
          }
        : { category_id: flowDetails?.category?.id };
    dispatch(getsubCategory(payload?.category_id));
  }, [subSearch]);

  // console.log(
  //   'flowDetails?.category?.category_id',
  //   flowDetails?.[0]?.any_value
  // );

  useEffect(() => {
    if (flowDetails?.[0]?.sub_category) {
      const { sub_category_name, id } = flowDetails[0].sub_category;
      setSelectedSubCategory(sub_category_name); // Set name for display
      form.setFieldsValue({ sub_category_id: id }); // Set ID for backend
    }
  }, [flowDetails, form]);

  useEffect(() => {
    setCategorySearch(flowDetails?.category?.category_name);
    setSbuSearch(flowDetails?.unit_name);
    setSubSearch(flowDetails?.sub_category_name);
    setProjectSearch(flowDetails?.project_code);
    //
    const flowDesignations =
      flowDetails?.[0]?.approvers?.map((item) => ({
        designation: item?.designation,
        display_name: item?.display_name || '',
        email: item?.email || ''
      })) || [];

    console.log('flowDesignations', flowDesignations);
    console.log('flowDetails', flowDetails);
    const payload = { category_id: flowDetails?.[0]?.category?.id };
    console.log('payload_123', payload?.category_id);
    dispatch(getsubCategory(payload?.category_id));
    form.setFieldsValue({
      doa_type: flowDetails?.doa_type,
      category_id: flowDetails?.[0]?.category?.id,
      sub_category_id: flowDetails?.[0]?.sub_category?.id,
      sub_category_name: flowDetails?.[0]?.sub_category?.sub_category_name,
      title: flowDetails?.[0]?.title,
      plant_id: flowDetails?.[0]?.plant_id,
      min_value: flowDetails?.[0]?.min_value,
      max_value: flowDetails?.[0]?.max_value,
      department_id: flowDetails?.[0]?.department_id,
      activity_id: flowDetails?.[0]?.activity_id
      // anyValue: flowDetails?.[0]?.any_value
    });
    if (
      Number(flowDetails?.[0]?.min_value) === 999 &&
      Number(flowDetails?.[0]?.max_value) === 999
    ) {
      setAnyValue(true);
    } else {
      setAnyValue(false);
    }
    const updatedFormValues = flowDetails?.[0]?.approvers?.map((item) => ({
      sequence: Number(item.sequence),
      // role_master_id: item.approval_role_id,
      designation_id: item.designation_id,
      // user_id: `${item.display_name} (${item.email})`
      user_id: item.user_id,
      user_details: `${item.display_name} (${item.email})`
      // designation_id: item.designation_id
    }));
    // .sort((a, b) => a.id - b.id);
    console.log('updatedFormValues', updatedFormValues);

    setFormValues(
      updatedFormValues || [{ sequence: 1, user_id: '', designation_id: '' }]
    );

    // Populate the role_master_id values in the form fields
    const fieldValues = {};
    const fieldValues1 = {};
    updatedFormValues?.forEach((item) => {
      const fieldName1 = `user_id${item.sequence}`;

      fieldValues[fieldName1] = item.user_id;
    });

    updatedFormValues?.forEach((item) => {
      const fieldName = `designation_id${item.sequence}`;

      fieldValues1[fieldName] = item.designation_id;
    });
    // form.setFieldsValue(fieldValues);
    // form.setFieldsValue(fieldValues1);
    form.setFieldsValue({
      ...fieldValues,
      ...fieldValues1,
      sub_category_name: flowDetails?.[0]?.sub_category?.sub_category_name // Ensure sub_category_name is set here
    });
  }, [flowDetails, form]);

  const handleGetAllProjects = () => {
    let payload = { search: projectSearch };
    dispatch(getAllProjects(payload));
  };

  const handleGetAllDesignations = () => {
    let payload = { search: designSearch, is_limit: 'false' };
    dispatch(getAllDesignationsList(payload));
  };

  useEffect(() => {
    handleGetAllDesignations();
  }, [designSearch]);

  useEffect(() => {
    handleGetAllProjects();
  }, [projectSearch]);

  const handleDesignSearchChange = (e) => {
    setDesignSearch(e);
  };

  const onFinish = (values) => {
    values['id'] = params.id;
    values['approvers'] = formValues;
    const { approvers, ...otherValues } = values;

    // console.log('values', values);
    // console.log('otherValues', otherValues);
    const doaFlows = approvers?.map((approver, index) => ({
      sequence: index + 1,
      // approver_id: approver?.role_master_id
      designation_id: approver.designation_id,
      approver_id: approver.user_id
    }));
    const payload = {
      id: params.id,
      plant_id: otherValues.plant_id,
      activity_id: otherValues.activity_id,
      department_id: otherValues.department_id,
      category_id: otherValues.category_id,
      sub_category_id: otherValues.sub_category_id,
      title: otherValues.title,
      max_value: otherValues.max_value,
      min_value: otherValues.min_value,
      any_value: anyValue,
      approvers: doaFlows
    };
    console.log('payload', payload);
    dispatch(editDoaFlow(payload)).then((res) => {
      if (res.payload.success) {
        message.success(res.payload.message);
        history.push('/settings/doa-flows');
      } else {
        message.error(res.payload.message);
      }
    });
  };

  const handleGetAllSBUs = () => {
    let payload = sbuSearch !== '' ? { search: sbuSearch } : { page: 1 };
    dispatch(getAllbus(payload));
  };

  // const addInput = () => {
  //   var highestValue = 1;
  //   for (var i = 1, len = formValues.length; i < len; i++) {
  //     var value = Number(formValues[i]['sequence']);
  //     if (value > highestValue) {
  //       highestValue = value;
  //     }
  //   }
  //   let id = highestValue + 1;
  //   setFormValues([
  //     ...formValues,
  //     { sequence: id, user_id: '', designation_id: '' }
  //   ]);
  //   // const fieldName = `role_master_id${id}`;
  //   const fieldName1 = `user_id${id}`;
  //   const fieldName = `designation_id${id}`;
  //   form.setFieldsValue({
  //     [fieldName]: undefined,
  //     [fieldName1]: undefined
  //   });
  // };

  const addInput = () => {
    var highestValue = 1;
    for (var i = 1, len = formValues.length; i < len; i++) {
      var value = Number(formValues[i]['sequence']);
      if (value > highestValue) {
        highestValue = value;
      }
    }
    let id = highestValue + 1;

    setFormValues([
      ...formValues,
      { sequence: id, user_id: '', designation_id: '' }
    ]);

    const fieldName1 = `user_id${id}`;
    const fieldName = `designation_id${id}`;

    // Reset form fields
    form.setFieldsValue({
      [fieldName]: undefined,
      [fieldName1]: undefined
    });

    // Clear error message and filtered users for the new row
    setRowErrors((prev) => ({
      ...prev,
      [id]: null
    }));

    setFilteredUsersByRow((prev) => ({
      ...prev,
      [id]: []
    }));
  };

  // const handleRemoveFormItem = (sequence) => {
  //   if (sequence === 1) {
  //     // Do not remove sequence 0
  //     return;
  //   }

  //   const updatedValues = formValues.filter(
  //     (item) => item.sequence !== sequence
  //   );

  //   // Update the sequence numbers for the remaining items
  //   const updatedValuesWithNewSequences = updatedValues.map((item, index) => ({
  //     ...item,
  //     sequence: index + 1
  //   }));

  //   setFormValues(updatedValuesWithNewSequences);

  //   // Reset form fields for the remaining items
  //   const updatedFormValues = {};
  //   updatedValuesWithNewSequences.forEach((item) => {
  //     const fieldName1 = `user_id${item.sequence}`;
  //     const fieldName = `designation_id${item.sequence}`;
  //     updatedFormValues[fieldName1] =
  //       item.user_id === '' ? undefined : item.user_id;
  //     updatedFormValues[fieldName] =
  //       item.designation_id === '' ? undefined : item.designation_id;
  //   });
  //   form.setFieldsValue(updatedFormValues);
  // };
  const handleRemoveFormItem = (sequence) => {
    if (sequence === 1) {
      return;
    }

    const updatedValues = formValues.filter(
      (item) => item.sequence !== sequence
    );

    const updatedValuesWithNewSequences = updatedValues.map((item, index) => ({
      ...item,
      sequence: index + 1
    }));

    setFormValues(updatedValuesWithNewSequences);

    // Reset form fields for remaining items
    const updatedFormValues = {};
    updatedValuesWithNewSequences.forEach((item) => {
      const fieldName1 = `user_id${item.sequence}`;
      const fieldName = `designation_id${item.sequence}`;
      updatedFormValues[fieldName1] =
        item.user_id === '' ? undefined : item.user_id;
      updatedFormValues[fieldName] =
        item.designation_id === '' ? undefined : item.designation_id;
    });
    form.setFieldsValue(updatedFormValues);

    // Remove error and filtered users for the deleted row
    setRowErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[sequence];
      return newErrors;
    });

    setFilteredUsersByRow((prev) => {
      const newFiltered = { ...prev };
      delete newFiltered[sequence];
      return newFiltered;
    });
  };
  // const onChangeApprover = (data, index) => {
  //   const newState = formValues.map((obj) => {
  //     if (obj.sequence === index + 1) {
  //       return { ...obj, sequence: index + 1, role_master_id: data };
  //     }
  //     return obj;
  //   });

  //   setFormValues(newState);
  // };

  const onChangeDesignation = (data, index) => {
    const newState = formValues.map((obj) => {
      if (obj.sequence === index + 1) {
        // return { ...obj, sequence: index + 1, designation_id: data };
        return { ...obj, sequence: index + 1, user_id: data };
      }
      return obj;
    });

    setFormValues(newState);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    if (sourceIndex === destinationIndex) return;

    const updatedValues = [...formValues];

    // Reorder the dragged item in the array
    const [draggedItem] = updatedValues.splice(sourceIndex, 1);
    updatedValues.splice(destinationIndex, 0, draggedItem);

    // Update the sequence numbers
    const updatedValuesWithNewSequences = updatedValues.map((item, index) => ({
      ...item,
      sequence: index + 1
    }));

    setFormValues(updatedValuesWithNewSequences);
    const updatedFormValues = {};
    updatedValuesWithNewSequences.forEach((item) => {
      const fieldName1 = `user_id${item.sequence}`;
      const fieldName = `designation_id${item.sequence}`;
      updatedFormValues[fieldName1] =
        item.user_id === '' ? undefined : item.user_id;
      updatedFormValues[fieldName] =
        item.designation_id === '' ? undefined : item.designation_id;
    });
    form.setFieldsValue(updatedFormValues);
  };

  // Define the debounced version of the handleSearchChange function
  // const debouncedHandleSearchChange = debounce((e) => {
  //   setSearch(e);
  // }, 600);

  // const handleSearchChange = (e) => {
  //   debouncedHandleSearchChange(e); // Call the debounced function
  // };

  // // handleSearchChange();

  console.log(formValues);

  // useEffect(() => {
  //   handleGetAllApprovers();
  // }, [search]);

  useEffect(() => {
    handleGetAllSBUs();
  }, [sbuSearch]);
  //
  const handleSubCategorySearch = (e) => {
    setSubSearch(e);
  };

  const handleGetCategories = () => {
    const payload = categorySearch != '' ? { search: categorySearch } : {};
    dispatch(getcategory(payload));
  };

  const onChangeCategory = (id) => {
    // setSelectedCategoryId(id);
    handleGetSubCategories(id);
    form.setFieldsValue({ sub_category_id: undefined });
  };
  // };

  // const onSelectSubCategory = (value) => {
  console.log('get_subcategorylist', get_subcategorylist);

  const onSelectSubCategory = (value) => {
    console.log('value123', value);
    const selectedSubCategory = get_subcategorylist.find(
      (item) => item.id === value
    );
    if (selectedSubCategory) {
      setSelectedSubCategory(selectedSubCategory.sub_category_name); // Set the name for display
      form.setFieldsValue({ sub_category_id: value }); // Set the ID for backend
    }
  };
  console.log('setSelectedSubCategory', selectedSubCategory);

  useEffect(() => {
    handleGetCategories();
  }, [categorySearch]);

  useEffect(() => {
    handlegetPlants();
    handlegetfunctions();
    handlegetcategory();
    handlegetactivity();
    handlegetDesignations();
  }, []);

  const handlegetPlants = () => {
    dispatch(getPlants());
  };

  const handlegetfunctions = () => {
    dispatch(getfunctions());
  };

  const handlegetcategory = () => {
    dispatch(getcategory());
  };

  const handlegetactivity = () => {
    dispatch(getactivity());
  };

  const handlegetDesignations = (payload) => {
    dispatch(getAllDesignations(payload));
  };

  // const handleDesignationSelect = (designationId, sequence, index) => {
  //   console.log('data', sequence);
  //   const newState = formValues.map((obj) => {
  //     if (obj.sequence === index + 1) {
  //       return { ...obj, sequence: index + 1, designation_id: designationId };
  //     }
  //     return obj;
  //   });
  //   setFormValues(newState);
  //   // Find the selected designation
  //   console.log('output', designationId);
  //   const selectedDesignation = get_designationDetails.find(
  //     (designation) => designation.id === designationId
  //   );

  //   const usersForDesignation = selectedDesignation?.users || [];

  //   if (usersForDesignation.length > 0) {
  //     // Update the filtered users for the specific row
  //     setFilteredUsersByRow((prev) => ({
  //       ...prev,
  //       [sequence]: usersForDesignation
  //     }));

  //     // Clear the error for this row
  //     setRowErrors((prev) => ({
  //       ...prev,
  //       [sequence]: null
  //     }));
  //   } else {
  //     // If no users are found, clear the filtered users for this row
  //     setFilteredUsersByRow((prev) => ({
  //       ...prev,
  //       [sequence]: []
  //     }));

  //     // Set an error message for this row
  //     setRowErrors((prev) => ({
  //       ...prev,
  //       [sequence]: 'No users found for the selected designation.'
  //     }));
  //   }
  // };
  const handleDesignationSelect = (designationId, sequence, index) => {
    console.log('data', sequence);

    // Update formValues with new designation
    const newState = formValues.map((obj) => {
      if (obj.sequence === index + 1) {
        return { ...obj, sequence: index + 1, designation_id: designationId };
      }
      return obj;
    });
    setFormValues(newState);

    // Find the selected designation
    console.log('output', designationId);
    const selectedDesignation = get_designationDetails.find(
      (designation) => designation.id === designationId
    );

    const usersForDesignation = selectedDesignation?.users || [];

    if (usersForDesignation.length > 0) {
      setFilteredUsersByRow((prev) => ({
        ...prev,
        [sequence]: usersForDesignation
      }));

      // Clear any existing error
      setRowErrors((prev) => ({
        ...prev,
        [sequence]: null
      }));
    } else {
      // Clear filtered users
      setFilteredUsersByRow((prev) => ({
        ...prev,
        [sequence]: []
      }));

      // Set error message
      setRowErrors((prev) => ({
        ...prev,
        [sequence]: 'No users found for the selected designation.'
      }));

      // Clear the user selection if any
      const fieldName1 = `user_id${sequence}`;
      form.setFieldsValue({
        [fieldName1]: undefined
      });
    }
  };

  console.log('filteredUsersByRow', filteredUsersByRow);
  useEffect(() => {
    if (flowDetails?.length > 0) {
      const values = flowDetails[0].approvers.map((item) => ({
        sequence: Number(item.sequence),
        designation_id: item.designation_id,
        user_id: item.user_id,
        user_details: `${item.display_name} (${item.email})`
      }));
      setUpdatedFormValues(values);
    }
  }, [flowDetails]);

  const Bread = () => {
    return (
      <div className={`${styles.breadcrub_container}`}>
        <Row>
          <Col span={19}>
            <Breadcrumb separator={<RightOutlined />}>
              <Breadcrumb.Item href="/settings">Settings</Breadcrumb.Item>

              <Breadcrumb.Item
                className="sfprotext-medium font-14"
                href="/settings/doa-flows"
              >
                Configure DOA Flows
              </Breadcrumb.Item>

              <Breadcrumb.Item
                className="sfprotext-medium font-14"
                href={`/settings/edit-doa-flow/${params.id}`}
              >
                Edit DOA Flow
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col className="text-right" span={4}>
            <Button
              htmlType="submit"
              className="font-14 sfprotext-bold "
              type="primary"
            >
              Save
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <CustomLayout sider={false}>
      <Form
        onFinish={onFinish}
        form={form}
        layout="vertical"
        className="ant-form ant-form-vertical"
        initialValues={{ approvers: [''] }}
      >
        <Bread />
        <div className="mt-3">
          <Card className={`${styles.newForm}`} hoverable>
            <Row>
              <Col className="font-16 sfprotext-bold mb-4" span={24}>
                Select Flow Details
              </Col>

              {/* Title and Plant */}

              <Col className="mr-5" xs={24} xl={11} span={11}>
                <FormItem
                  name="title"
                  label="Title"
                  rules={[
                    {
                      required: true,
                      message: 'Please select'
                    }
                  ]}
                >
                  <Input
                    // className="w-100"
                    placeholder="Enter title"
                  />
                </FormItem>
              </Col>
              <Col className="" xs={24} xl={11} span={11}>
                <FormItem
                  name="plant_id"
                  label="Plant"
                  rules={[
                    {
                      required: true,
                      message: 'Please plant'
                    }
                  ]}
                >
                  <Select
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onSearch={handleSubCategorySearch}
                    showSearch
                    suffixIcon={<CaretDownFilled />}
                    placeholder="Select Plant"
                  >
                    {get_plants
                      // ?.filter((item) => item.new_sub_cat_doa)
                      ?.map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.is_active === false
                            ? `${item.name} (Deactivated)`
                            : item.name}
                        </Option>
                      ))}
                  </Select>
                </FormItem>
              </Col>
              {/* Title and Plant End */}

              <Col className="mr-5" xs={24} xl={11} span={11}>
                <FormItem
                  name="category_id"
                  label="Category"
                  rules={[
                    {
                      required: true,
                      message: 'Please select'
                    }
                  ]}
                >
                  <Select
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    showSearch
                    onSearch={handleCategorySearch}
                    suffixIcon={<CaretDownFilled />}
                    onChange={onChangeCategory}
                    placeholder="Select Category"
                  >
                    {' '}
                    {get_category
                      // ?.filter((item) => item.new_cat_doa)
                      ?.map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.is_active === false
                            ? `${item.category_name} (Deactivated)`
                            : item.category_name}
                        </Option>
                      ))}
                  </Select>
                </FormItem>
              </Col>
              <Col className="" xs={24} xl={11} span={11}>
                <FormItem
                  name="sub_category_id"
                  label="Sub-Category"
                  rules={[
                    {
                      required: true,
                      message: 'Please select'
                    }
                  ]}
                >
                  <Select
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    // value={selectedSubCategory}
                    showSearch
                    // onSearch={handleSubSearch}
                    // onChange={onSelectSubCategory}
                    onSelect={(value, option) =>
                      onSelectSubCategory(value, option)
                    }
                    suffixIcon={<CaretDownFilled />}
                    placeholder="Select Sub-Category"
                  >
                    {get_subcategorylist
                      // .filter((item) => item.new_sub_cat_doa)
                      ?.map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.is_active === false
                            ? `${item.sub_category_name} (Deactivated)`
                            : item.sub_category_name}
                        </Option>
                      ))}
                  </Select>
                </FormItem>
              </Col>

              {/* Department and Activity */}
              <Col className="mr-5" xs={24} xl={11} span={11}>
                <FormItem
                  name="department_id"
                  label="Department"
                  rules={[
                    {
                      required: true,
                      message: 'Please department'
                    }
                  ]}
                >
                  <Select
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onSearch={handleSubCategorySearch}
                    showSearch
                    suffixIcon={<CaretDownFilled />}
                    placeholder="Select Department"
                  >
                    {get_functions
                      // ?.filter((item) => item.new_sub_cat_doa)
                      ?.map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.is_active === false
                            ? `${item.name} (Deactivated)`
                            : item.function_name}
                        </Option>
                      ))}
                  </Select>
                </FormItem>
              </Col>
              <Col className="" xs={24} xl={11} span={11}>
                <FormItem
                  name="activity_id"
                  label="Activity"
                  rules={[
                    {
                      required: true,
                      message: 'Please select activity'
                    }
                  ]}
                >
                  <Select
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onSearch={handleSubCategorySearch}
                    showSearch
                    suffixIcon={<CaretDownFilled />}
                    placeholder="Select activity"
                  >
                    {get_activity
                      // ?.filter((item) => item.new_sub_cat_doa)
                      ?.map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.is_active === false
                            ? `${item.name} (Deactivated)`
                            : item.name}
                        </Option>
                      ))}
                  </Select>
                </FormItem>
              </Col>

              {/* Department and Activiti End */}

              <Col className={'mr-5'} xs={24} xl={11} span={11}>
                <FormItem
                  name="min_value"
                  label="Value (Min)"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a value'
                    }
                  ]}
                >
                  <InputNumber
                    precision={0}
                    disabled={anyValue}
                    className="w-100"
                    placeholder="Enter Min Budget"
                  />
                </FormItem>
              </Col>
              <Col className="" xs={24} xl={11} span={11}>
                <FormItem
                  name="max_value"
                  label="Value (Max)"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a value'
                    }
                  ]}
                >
                  <InputNumber
                    precision={0}
                    disabled={anyValue}
                    className="w-100"
                    placeholder="Enter Max Budget"
                  />
                </FormItem>
              </Col>
              <Col span={24}>
                <Checkbox
                  className="mb-3"
                  // defaultChecked={anyValue}
                  checked={anyValue}
                  onChange={toggleCheckbox}
                  value={anyValue}
                />{' '}
                <span>I want to mark the budget as {`"Any Value"`}</span>
              </Col>
            </Row>
          </Card>
          <Card className={`mt-3 ${styles.newForm}`} hoverable>
            <Col className="mr-5" xs={24} xl={11} span={11}></Col>
            <Col className="font-16 sfprotext-bold mb-4" span={24}>
              Add Approvers
            </Col>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="formItems">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {formValues.map((formItem, index) => (
                      <Draggable
                        key={formItem.sequence} // Use sequence as the key
                        draggableId={formItem?.sequence?.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <Row
                              gutter={{
                                xs: 8,
                                sm: 16,
                                md: 24,
                                lg: 32
                              }}
                            >
                              <Col className="gutter-row" span={1}>
                                <div
                                  className={`${
                                    index < 0
                                      ? `styles.step ${styles.v_stepper}${styles.circle}`
                                      : ''
                                  } mt-2`}
                                >
                                  <div className={`${styles.circle}`}>
                                    <p>{index + 1}</p>
                                  </div>
                                  <div
                                    className={
                                      formValues.length !== index + 1 &&
                                      !snapshot.isDragging
                                        ? `${styles.line}`
                                        : ''
                                    }
                                  ></div>
                                </div>
                              </Col>
                              <Col className="gutter-row" xs={1} span={1}>
                                <div className="mt-2">
                                  <HolderOutlined />
                                </div>
                              </Col>
                              <Col
                                xs={11}
                                xl={8}
                                className="gutter-row"
                                span={6}
                              >
                                <div>
                                  <Form.Item
                                    // name={`role_master_id${formItem.sequence}`}
                                    name={`designation_id${formItem.sequence}`}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please select'
                                      }
                                    ]}
                                  >
                                    <Select
                                      placeholder="Select Designation"
                                      // value={formItem.designation_id}
                                      showSearch
                                      onSearch={handleDesignationSearchChange}
                                      // onSearch={(e) => handleSearchChange(e)}
                                      suffixIcon={<CaretDownOutlined />}
                                      // onSelect={
                                      //   (value) =>
                                      //     onChangeApprover(value, index) // Corrected parameter order
                                      // }
                                      onSelect={(value) =>
                                        handleDesignationSelect(
                                          value,
                                          formItem.sequence,
                                          index
                                        )
                                      } // Updated handler
                                    >
                                      {get_designationDetails?.map(
                                        (designation) => (
                                          <Option
                                            key={designation.id}
                                            value={designation.id}
                                          >
                                            {designation.is_active === false
                                              ? `${designation.designation} (Deactivated)`
                                              : designation.designation}
                                          </Option>
                                        )
                                      )}
                                    </Select>
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col
                                xs={11}
                                xl={8}
                                className="gutter-row"
                                span={6}
                              >
                                <div>
                                  <FormItem
                                    name={`user_id${formItem.sequence}`}
                                    rules={[
                                      {
                                        required:
                                          !!filteredUsersByRow[
                                            formItem.sequence
                                          ]?.length,
                                        message:
                                          rowErrors[formItem.sequence] ||
                                          'Please select a user'
                                      }
                                    ]}
                                  >
                                    <Select
                                      placeholder="Select User"
                                      showSearch
                                      labelInValue
                                      onSearch={handleDesignSearchChange}
                                      disabled={
                                        !filteredUsersByRow[formItem.sequence]
                                          ?.length
                                      }
                                      suffixIcon={<CaretDownFilled />}
                                      filterOption={(inputValue, option) =>
                                        option.props.children
                                          .toUpperCase()
                                          .indexOf(inputValue.toUpperCase()) !==
                                        -1
                                      }
                                      onSelect={
                                        (value) =>
                                          onChangeDesignation(value, index) // Corrected parameter order
                                      }
                                      // options={updatedFormValues?.map(
                                      //   (item) => ({
                                      //     value: item.user_id, // Actual value submitted
                                      //     label: item.user_details // Displayed value
                                      //   })
                                      // )}
                                      // >
                                      //   {(
                                      //     filteredUsersByRow[formItem.sequence] ||
                                      //     []
                                      //   ).map((user) => (
                                      //     <Option key={user.id} value={user.id}>
                                      //       {user.is_active === false
                                      //         ? `${user.display_name} (${user.email}) (Deactivated)`
                                      //         : `${user.display_name} (${user.email})`}
                                      //     </Option>
                                      //   ))}
                                      // options={filteredUsersByRow[
                                      //   formItem.sequence
                                      // ]?.map((user) => ({
                                      //   value: user.id,
                                      //   label: user.is_active
                                      //     ? `${user.display_name} (${user.email})`
                                      //     : `${user.display_name} (${user.email}) (Deactivated)`
                                      // }))}
                                      // options={filteredUsersByRow[
                                      //   formItem.sequence
                                      // ]?.map((user) => {
                                      //   const isSelected =
                                      //     form.getFieldValue(
                                      //       `user_id${formItem.sequence}`
                                      //     )?.value === user.id;
                                      //   console.log('user_details', user);
                                      //   return {
                                      //     value: user.id, // Backend ID
                                      //     label: isSelected
                                      //       ? user.user_details // Display `user_details` for already selected users
                                      //       : user.is_active
                                      //       ? `${user.display_name} (${user.email})` // Display name and email for active users
                                      //       : `${user.display_name} (${user.email}) (Deactivated)` // Add "Deactivated" for inactive users
                                      //   };
                                      // })}
                                      // // //
                                      // options={
                                      //   formItem.isAlreadySelected
                                      //     ? updatedFormValues?.map((item) => ({
                                      //         value: item.user_id, // Actual value submitted
                                      //         label: item.user_details // Displayed value for already selected rows
                                      //       }))
                                      //     : filteredUsersByRow[
                                      //         formItem.sequence
                                      //       ]?.map((user) => {
                                      //         const isSelected =
                                      //           form.getFieldValue(
                                      //             `user_id${formItem.sequence}`
                                      //           )?.value === user.id;

                                      //         return {
                                      //           value: user.id, // Backend ID
                                      //           label: isSelected
                                      //             ? user.user_details // Display `user_details` for already selected users
                                      //             : user.is_active
                                      //             ? `${user.display_name} (${user.email})` // Display name and email for active users
                                      //             : `${user.display_name} (${user.email}) (Deactivated)` // Add "Deactivated" for inactive users
                                      //         };
                                      //       })
                                      // }
                                      // // // // //
                                      options={
                                        !filteredUsersByRow[formItem.sequence]
                                          ? updatedFormValues?.map((item) => {
                                              return {
                                                value: item.user_id, // Backend ID
                                                label: item.user_details // Displayed value for already selected rows
                                              };
                                            })
                                          : filteredUsersByRow[
                                              formItem.sequence
                                            ]?.map((user) => {
                                              const isSelected =
                                                form.getFieldValue(
                                                  `user_id${formItem.sequence}`
                                                )?.value === user.id;

                                              return {
                                                value: user.id, // Backend ID
                                                label: isSelected
                                                  ? user.user_details // Display `user_details` for already selected users
                                                  : user.is_active
                                                  ? `${user.display_name} (${user.email})` // Display name and email for active users
                                                  : `${user.display_name} (${user.email}) (Deactivated)` // Add "Deactivated" for inactive users
                                              };
                                            })
                                      }
                                    />
                                    {/* </Select> */}
                                  </FormItem>
                                </div>
                                {rowErrors[formItem.sequence] && (
                                  <p style={{ color: 'red' }}>
                                    {rowErrors[formItem.sequence]}
                                  </p>
                                )}
                              </Col>
                              <Col
                                className="gutter-row"
                                xs={3}
                                xl={6}
                                span={6}
                              >
                                <div>
                                  {index > 0 && (
                                    <DeleteOutlined
                                      onClick={() =>
                                        handleRemoveFormItem(formItem.sequence)
                                      }
                                      className={`cursor-pointer ${styles.delete_icon}`}
                                    />
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {/* <div>
              {formValues.map((item, index) => (
                <div key={index}>
                  <span>Sequence: {item.sequence}</span>
                  <span>Role ID: {item.role_master_id}</span>
                </div>
              ))}
            </div> */}

            <span
              onClick={addInput}
              className={`cursor-pointer ${styles.span_add}`}
            >
              <PlusCircleFilled />
              {'\u00a0'} Add Roles
            </span>
          </Card>
        </div>
      </Form>
    </CustomLayout>
  );
};

export default EditDOAPage;
