import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { cloneingData } from './api';

const initialState = {
  cloning_data: [],
  pagination: null
};

const actions = {
  GETCLONEBYID: 'getclonebyid/GETCLONEBYID'
  //   GETSEARCHLIST: 'getSearchList/GETSEARCHLIST'
};

export const getclonebyid = createAsyncThunk(
  actions.GETCLONEBYID,
  async (payload) => {
    // console.log('slicePayload', payload.id);
    const response = await cloneingData('one-envelope', payload);
    return response;
  }
);

export const getCloneSlice = createSlice({
  name: 'actionsList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getclonebyid.pending, (state) => {
        state.loading = true;
      })
      .addCase(getclonebyid.fulfilled, (state, action) => {
        // console.log('payload', action.payload.data);
        // const { data } = action.payload.data;
        // console.log('payload_data', data);
        state.cloning_data = action.payload.data;
      })
      .addCase(getclonebyid.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });
  }
});

export default getCloneSlice.reducer;
